<template>
  <div>
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-else>
      <div class="mb-6 flex justify-between items-center">
        <h1 class="text-3xl font-bold">Instructor</h1>
        <Button buttonText="Add Instructor" type="primary" @action="addData" />
      </div>
      <div class="bg-white p-4 shadow-small rounded-lg card">
        <div class="flex mb-5 gap-5">
          <div class="w-1/3">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Search instructor</label>
            <div class="relative">
              <TextField type="text" :enterKeyAction="onSearch" withIcon borderEnabled v-model="params['keyword']"
                placeholder="Search by name, email, phone number" />
              <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
                <Search />
              </div>
            </div>
          </div>
          <div class="w-60">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Filter By Program</label>
            <Dropdown :options="programOptions" optionLabel="name" v-model="programSelected" :isReset="isResetDropdown"
              @change="filterByProgram()" placeholder="Select Program" />
          </div>
          <div class="my-auto pt-5">
            <Button buttonText="Apply Filter" type="secondary" size="regular" additionalClass="py-3" @action="onSearch" />
          </div>
          <div class="my-auto pt-5">
            <Button buttonText="Clear" type="tertiary" @action="clearParameter" additionalClass="py-3"
              v-if="isClearButtonVisible" />
          </div>
        </div>
        <vue-good-table mode="remote" :pagination-options="{
          enabled: true,
          mode: 'pages',
          perPage: perPage,
          position: 'bottom',
          perPageDropdown: [5, 10],
          dropdownAllowAll: false,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All'
        }" :totalRows="totalData" :rows="instructorList" :columns="columns" :sort-options="{
  enabled: false
}" @on-page-change="onPageChange" @on-per-page-change="onSizePageChange" :row-style-class="rowStyleClassFn">
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'btn'" class="flex flex-col gap-2">
              <Button buttonText="Edit" type="tertiary" size="full" @action="editMaster(props.row.id)" />
              <Button buttonText="Delete" type="delete" @action="showDeleteConfirmModal(props.row)" />
            </div>
            <div v-else-if="props.column.field === 'programs'">
              <div v-for="program in props.formattedRow[props.column.field]" :key="program">
                {{ program }}
              </div>
              <div v-if="props.formattedRow[props.column.field].length === 0">-</div>
            </div>
            <div v-else-if="props.column.field === 'created_date'">
              <span style="display: block; white-space: nowrap">
                {{ formatDate(props.formattedRow[props.column.field]).date }}
              </span>
              <span style="display: block; font-size: 14px; color: #333">
                {{ formatDate(props.formattedRow[props.column.field]).time }}
              </span>
            </div>
            <div v-else-if="props.column.field === 'admin_access'"><Button buttonText="Get Access"
                @action="getInstructorAccess(props.row)" /></div>
            <span v-else-if="props.column.field == 'url'">
              <img :src="props.row.url +
                (($route.meta.testingName == 'h') | ($route.meta.testingName == 'i') | ($route.meta.testingName == 'j') | ($route.meta.testingName == 'k') | ($route.meta.testingName == 'z')
                  ? '?onlyAnnotation=0'
                  : '')
                " class="img-fluid" alt="Image" />
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <Modal :modalVisible="isDeleteConfirmationModalVisible" @close="toogleDeleteConfirmModal" width="640px" id="confirm">
      <template slot="modal-content">
        <div class="py-5">
          <div class="font-bold text-2xl">Are you sure to delete instructor?</div>
          <div class="py-5 text-sm">This action can not be undone</div>
          <div class="flex justify-center items-center">
            <Button buttonText="Delete" type="secondary" size="long" additionalClass="mr-16" @action="deleteMaster" />
            <Button buttonText="Cancel" type="primary" size="long" @action="toogleDeleteConfirmModal" />
          </div>
        </div>
      </template>
    </Modal>
    <Modal :modalVisible="isAssignmentModalVisible" @close="toogleInstructorAssignmentModal" width="640px"
      id="assingment">
      <template slot="modal-content">
        <div class="py-12">
          <div class="font-bold text-2xl">Please assign new instructor</div>
          <div class="py-5 text-sm">The instructor teaches in {{ totalClass }} classes, you have to find a replacement
            instructor</div>
          <div class="mb-7 flex justify-center">
            <div class="w-3/5 relative">
              <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">New instructor</label>
              <div class="relative">
                <TextField type="text" :enterKeyAction="onSearchInstructorResult" withIcon borderEnabled
                  v-model="options2.nama" placeholder="Search instructor name" />
                <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
                  <Search />
                </div>
              </div>
              <div class="text-xs pt-1 text-yellow text-left">click enter to search instructor</div>
              <div
                class="absolute left-0 py-2 max-h-40 overflow-y-auto rounded-lg w-full bg-neutral-500 bg-opacity-90 z-10"
                v-if="isSearching && options2.nama !== '' && !isFetching">
                <template v-if="instructorListResult.length > 0 && !isFetching">
                  <div @click="selectIntructor(instructor)"
                    class="text-left py-1 px-3 text-white cursor-pointer hover:bg-yellow-primary hover:text-white font-normal last:pb-0"
                    v-for="instructor in instructorListResult" :key="instructor.id">
                    {{ instructor.name }}
                  </div>
                </template>
                <template v-if="instructorListResult.length === 0 && !isFetching">
                  <p class="py-1 px-3 text-white font-normal text-center">Instructor is not found.</p>
                </template>
              </div>
            </div>
          </div>

          <div class="flex justify-center items-center">
            <Button buttonText="Delete" type="secondary" :disabled="!instructorSelected.id" size="long"
              additionalClass="mr-16" @action="assignIntructor" />
            <Button buttonText="Cancel" type="primary" size="long" @action="toogleInstructorAssignmentModal" />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import moment from 'moment'
import { showVueToast } from '@/utils'
import { mapActions } from 'vuex'
export default {
  name: 'Kelas',
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal')
  },
  mounted: function () {
    this.initData()
    this.getProgramOptions()
  },
  data: function () {
    return {
      params: {
        sortBy: 'createdDate',
        page: 0,
        size: 10,
        keyword: '',
        program: '',
        direction: 'DESC'
      },
      isResetDropdown: false,
      totalData: 0,
      instructorList: [],
      programOptions: [],
      programSelected: null,
      isContainsSpecialCharacter: false,
      isDeleteConfirmationModalVisible: false,
      isAssignmentModalVisible: false,
      searchTerm: null,
      options: {
        limit: 11,
        page: 1,
        sort: 'name',
        nama: ''
      },
      options2: {
        limit: 11,
        page: 1,
        sort: 'name',
        nama: ''
      },
      isFetching: false,
      isSearching: false,
      currentKeyword: '',
      instructorSelected: {},
      instructorListResult: [],
      totalClass: 0,
      masterId: '',
      perPage: 10,
      showProgress: true,
      counterProgress: 0,
      modeForm: '',
      locked: false,
      unlockPassword: '',
      stillLoading: true,
      testingName: '',
      isKeywordSameTwice: false,
      existingInstructor: ''
    }
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('instructur', ['GET_INTRUCTOR_ACCESS']),
    getInstructorAccess(data) {
      this.showLoading()
      const params = {
        customerId: data.customer_id,
        instructorId: data.id
      }
      this.GET_INTRUCTOR_ACCESS(params)
        .then((res) => {
          if (res.status === 200) {
            showVueToast('Success! please, check your email', 'success', 3000)
            this.hideLoading()
            return
          }
          showVueToast('Failed!', 'error', 2000)
          this.hideLoading()
        })
        .catch(() => {
          showVueToast('Failed!', 'error', 2000)
          this.hideLoading()
        })
    },
    toogleDeleteConfirmModal() {
      this.isDeleteConfirmationModalVisible = !this.isDeleteConfirmationModalVisible
    },
    toogleInstructorAssignmentModal() {
      this.isAssignmentModalVisible = !this.isAssignmentModalVisible
      this.options2.nama = ''
    },
    formatDate(date) {
      if (date) {
        return {
          date: moment(date).local().format('DD MMM YYYY'),
          time: moment(date).local().format('HH:mm:ss')
        }
      } else {
        return '-'
      }
    },
    selectIntructor(data) {
      this.isSearching = false
      this.isFetching = false
      this.options2.nama = data.name
      this.currentKeyword = data.name
      this.instructorSelected = data
    },

    getProgramOptions() {
      this.$store.dispatch('kelas/GET_LIST_PROGRAM').then((response) => {
        this.programOptions = response.data.data
      })
    },

    clearParameter() {
      this.params = {
        sortBy: 'name',
        page: 0,
        size: 11,
        keyword: '',
        program: ''
      }
      this.programSelected = null
      this.isResetDropdown = !this.isResetDropdown
      this.initData()
    },

    initData() {
      this.showLoading()
      let params = {}

      if (this.isContainsSpecialCharacter) {
        let indexEnd = 0
        let isIndexFilled = false
        for (let i = 0; i < this.keywordTyping.length; i++) {
          if (this.keywordTyping.charAt(i) === '@') {
            if (!isIndexFilled) indexEnd = i
            isIndexFilled = true
          } else {
            if (!isIndexFilled) indexEnd = this.keywordTyping.length
          }
        }

        const keyword = this.params.keyword.substring(0, indexEnd)
        const copyParams = JSON.parse(JSON.stringify(this.params))
        delete copyParams.keyword
        copyParams.keyword = keyword
        params = queryString.stringify({
          ...copyParams
        })
      } else {
        const copyParams = JSON.parse(JSON.stringify(this.params))
        copyParams.keyword = copyParams.keyword.replace(/\s+/g, '')
        params = queryString.stringify({
          ...copyParams
        })
      }

      this.$store
        .dispatch('instructur/GET_LIST_INSTRUCTUR_V3', {
          params,
          customerId: this.customerId
        })
        .then((res) => {
          this.instructorList = res.data.data
          this.totalData = res.data.pagination.total
          this.stillLoading = false
          this.hideLoading()
        })
        .catch((err) => {
          this.hideLoading()
        })
    },
    unlock() {
      if (this.unlockPassword == this.$store.getters['customer/customer'].password) {
        this.locked = false
      } else {
        showVueToast('Password invalid', 'warning', 2000)
      }
    },
    preview(masterId) {
      this.$router.push('/instructur/' + masterId)
    },
    assignIntructor() {
      this.showLoading()
      this.$store
        .dispatch('instructur/REASSIGN_INSTRUCTOR', {
          payload: {
            id: this.instructorSelected.id,
            customer_id: this.customerId
          },
          oldInstructorId: this.masterId,
          oldCustomerId: this.customerId
        })
        .then(() => {
          this.options2.nama = ''
          this.instructorList = this.instructorList.filter((person) => person.id !== this.masterId)
          showVueToast('Instructor removed successfully!', 'success', 2000)
          this.toogleInstructorAssignmentModal()
          // this.initData()
        })
        .catch(() => {
          this.hideLoading()
          showVueToast('Failed!', 'error', 2000)
        })
    },
    filterByProgram() {
      this.params.page = 0
      this.params.program = this.programSelected.code
      this.initData()
    },
    onSizePageChange(params) {
      this.params.page = 0
      this.params.size = params.currentPerPage
      this.initData()
    },
    onPageChange(params) {
      this.params.page = params.currentPage - 1
      this.initData()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    onSearchInstructorResult() {
      this.currentKeyword = this.options2.nama
      this.isSearching = true
      this.instructorListResult = []
      this.isFetching = true
      this.options2.page = 1
      this.options2.limit = 3
      let paramsTemp = queryString.stringify({
        ...{
          limit: null,
          page: null,
          nama: null,
          sort: '',
          testingName: this.$route.meta.testingName
        },
        ...this.options2
      })

      this.$store
        .dispatch('instructur/GET_LIST_INSTRUCTUR', {
          masterType: this.$route.meta.name,
          params: paramsTemp,
          customerId: this.customerId,
          testingName: this.$route.meta.testingName,
          isGetResponse: true
        })
        .then((response) => {
          this.instructorListResult = response.data.data.filter((ins) => ins.name !== this.existingInstructor)
          this.isFetching = false
        })
    },

    containsSpecialChars(str) {
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
      return specialChars.test(str)
    },

    onSearch() {
      this.params.page = 0
      this.initData()
      // this.options.page = 1
      // let paramsTemp = queryString.stringify({
      //   ...{
      //     limit: null,
      //     page: null,
      //     nama: null,
      //     sort: '',
      //     testingName: this.$route.meta.testingName
      //   },
      //   ...this.options
      // })

      // this.$store.dispatch('instructur/GET_LIST_INSTRUCTUR', {
      //   masterType: this.$route.meta.name,
      //   params: paramsTemp,
      //   customerId: this.customerId,
      //   testingName: this.$route.meta.testingName
      // })
    },
    editMaster(masterId) {
      this.$router.push('/instructur/edit/' + masterId)
    },
    showDeleteConfirmModal(instructor) {
      this.masterId = instructor.id
      this.existingInstructor = instructor.name
      this.toogleDeleteConfirmModal()
    },
    deleteMaster() {
      this.showLoading()
      this.$store
        .dispatch('instructur/DELETE_INSTRUCTUR', {
          masterType: this.$route.meta.name,
          masterId: this.masterId,
          customerId: this.customerId,
          testingName: this.$route.meta.testingName
        })
        .then((resp) => {
          if (resp.data.status == '200 OK') {
            this.instructorList = this.instructorList.filter((person) => person.id !== this.masterId)
            showVueToast('Instructor removed successfully!', 'success', 2000)
            this.isDeleteConfirmationModalVisible = false
            this.hideLoading()
            return
          }
          showVueToast('Failed!', 'error', 2000)
          this.isDeleteConfirmationModalVisible = false
          this.hideLoading()
        })
        .catch((err) => {
          if (err?.data) {
            this.totalClass = err?.data?.length
            this.hideLoading()
            this.isDeleteConfirmationModalVisible = false
            setTimeout(() => {
              this.toogleInstructorAssignmentModal()
            }, 50)
          } else {
            showVueToast('Failed!', 'error', 2000)
            this.isDeleteConfirmationModalVisible = false
            this.hideLoading()
          }
        })
    },

    addData() {
      this.$router.push('/' + this.$route.meta.name + '/Add')
    },
    openDisplay() {
      this.$router.push('/display/' + this.$store.getters['customer/customer'].token_trx)
    },
    resetDisplay() {
      this.$store.dispatch('customer/RESET_TOKEN_TRX', {}).then((resp) => {
        if (resp.data.status == '200 OK') {
          // this.$swal(resp.data.status)
        }
      })
    },
    rowStyleClassFn(row) {
      let result = 'default'
      // if (row.id % 2 == 1 ){
      //     result='green'
      // } else if (row.id == 0 ){
      //     result='red'
      // }
      return result
    }
  },
  computed: {
    isClearButtonVisible() {
      if (this.params.keyword === '' && this.params.program === '') return false
      return true
    },
    keywordTyping() {
      return this.params.keyword
    },
    keyword() {
      return this.options2.nama
    },
    customerId() {
      return localStorage.getItem('client')
    },
    data: function () {
      return this.$store.getters['instructur/list_instructur'] ? this.$store.getters['instructur/list_instructur'] : []
    },
    totalRecords: function () {
      return this.$store.getters['instructur/paginate'].total ? this.$store.getters['instructur/paginate'].total : 0
    },
    columns: function () {
      return [
        { field: 'id', label: 'Id' },
        { field: 'name', label: 'Name' },
        { field: 'hp', label: 'Phone Number' },
        { field: 'email', label: 'Email' },
        { field: 'programs', label: 'Program Type' },
        { field: 'admin_access', label: 'Admin Access' },
        { field: 'created_date', label: 'Created Date' },
        { field: 'btn', label: 'Action', tdClass: 'custom-action', thClass: 'custom-action-head' }
      ]
    },
    filters: function () {
      return [
        { field: 'nama', label: 'Name' },
        { field: 'hp', label: 'Phone Number' },
        { field: 'email', label: 'Email' }
      ]
    },
    level_id: function () {
      return this.$store.getters['customer/customer'] ? this.$store.getters['customer/customer'].level_id : 0
    }
  },
  created: function () {
    // this.initData()
  },
  watch: {
    keywordTyping(value) {
      if (this.containsSpecialChars(value)) {
        this.isContainsSpecialCharacter = true
        return
      }
      this.isContainsSpecialCharacter = false
    },
    $route(to, from) {
      this.showProgress = true
      this.initData()
    },
    keyword(value) {
      if (value === this.currentKeyword) {
        if (!this.isKeywordSameTwice) this.isSearching = true
      } else {
        this.instructorSelected = {}
        this.isKeywordSameTwice = true
        this.isSearching = false
      }
    }
  }
}
</script>
<style scoped lang="scss">
.red {
  background: linear-gradient(#f4f5f8, #e2133f);
}

.green {
  background: linear-gradient(#f4f5f8, #57c137);
}

.default {
  background: linear-gradient(#f4f5f8, #f1f3f6);
}

.card {
  &::v-deep .vgt-table thead th {
    white-space: nowrap;
  }
}
</style>
