var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', [_c('div', {
    staticClass: "mb-6 flex justify-between items-center"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("Instructor")]), _c('Button', {
    attrs: {
      "buttonText": "Add Instructor",
      "type": "primary"
    },
    on: {
      "action": _vm.addData
    }
  })], 1), _c('div', {
    staticClass: "bg-white p-4 shadow-small rounded-lg card"
  }, [_c('div', {
    staticClass: "flex mb-5 gap-5"
  }, [_c('div', {
    staticClass: "w-1/3"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Search instructor")]), _c('div', {
    staticClass: "relative"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "enterKeyAction": _vm.onSearch,
      "withIcon": "",
      "borderEnabled": "",
      "placeholder": "Search by name, email, phone number"
    },
    model: {
      value: _vm.params['keyword'],
      callback: function callback($$v) {
        _vm.$set(_vm.params, 'keyword', $$v);
      },
      expression: "params['keyword']"
    }
  }), _c('div', {
    staticClass: "absolute left-3 top-1/2 transform -translate-y-1/2"
  }, [_c('Search')], 1)], 1)]), _c('div', {
    staticClass: "w-60"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Filter By Program")]), _c('Dropdown', {
    attrs: {
      "options": _vm.programOptions,
      "optionLabel": "name",
      "isReset": _vm.isResetDropdown,
      "placeholder": "Select Program"
    },
    on: {
      "change": function change($event) {
        return _vm.filterByProgram();
      }
    },
    model: {
      value: _vm.programSelected,
      callback: function callback($$v) {
        _vm.programSelected = $$v;
      },
      expression: "programSelected"
    }
  })], 1), _c('div', {
    staticClass: "my-auto pt-5"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Apply Filter",
      "type": "secondary",
      "size": "regular",
      "additionalClass": "py-3"
    },
    on: {
      "action": _vm.onSearch
    }
  })], 1), _c('div', {
    staticClass: "my-auto pt-5"
  }, [_vm.isClearButtonVisible ? _c('Button', {
    attrs: {
      "buttonText": "Clear",
      "type": "tertiary",
      "additionalClass": "py-3"
    },
    on: {
      "action": _vm.clearParameter
    }
  }) : _vm._e()], 1)]), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "pagination-options": {
        enabled: true,
        mode: 'pages',
        perPage: _vm.perPage,
        position: 'bottom',
        perPageDropdown: [5, 10],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        // for 'pages' mode
        allLabel: 'All'
      },
      "totalRows": _vm.totalData,
      "rows": _vm.instructorList,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false
      },
      "row-style-class": _vm.rowStyleClassFn
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onSizePageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field == 'btn' ? _c('div', {
          staticClass: "flex flex-col gap-2"
        }, [_c('Button', {
          attrs: {
            "buttonText": "Edit",
            "type": "tertiary",
            "size": "full"
          },
          on: {
            "action": function action($event) {
              return _vm.editMaster(props.row.id);
            }
          }
        }), _c('Button', {
          attrs: {
            "buttonText": "Delete",
            "type": "delete"
          },
          on: {
            "action": function action($event) {
              return _vm.showDeleteConfirmModal(props.row);
            }
          }
        })], 1) : props.column.field === 'programs' ? _c('div', [_vm._l(props.formattedRow[props.column.field], function (program) {
          return _c('div', {
            key: program
          }, [_vm._v(" " + _vm._s(program) + " ")]);
        }), props.formattedRow[props.column.field].length === 0 ? _c('div', [_vm._v("-")]) : _vm._e()], 2) : props.column.field === 'created_date' ? _c('div', [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).date) + " ")]), _c('span', {
          staticStyle: {
            "display": "block",
            "font-size": "14px",
            "color": "#333"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).time) + " ")])]) : props.column.field === 'admin_access' ? _c('div', [_c('Button', {
          attrs: {
            "buttonText": "Get Access"
          },
          on: {
            "action": function action($event) {
              return _vm.getInstructorAccess(props.row);
            }
          }
        })], 1) : props.column.field == 'url' ? _c('span', [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": props.row.url + (_vm.$route.meta.testingName == 'h' | _vm.$route.meta.testingName == 'i' | _vm.$route.meta.testingName == 'j' | _vm.$route.meta.testingName == 'k' | _vm.$route.meta.testingName == 'z' ? '?onlyAnnotation=0' : ''),
            "alt": "Image"
          }
        })]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1)]), _c('Modal', {
    attrs: {
      "modalVisible": _vm.isDeleteConfirmationModalVisible,
      "width": "640px",
      "id": "confirm"
    },
    on: {
      "close": _vm.toogleDeleteConfirmModal
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "py-5"
  }, [_c('div', {
    staticClass: "font-bold text-2xl"
  }, [_vm._v("Are you sure to delete instructor?")]), _c('div', {
    staticClass: "py-5 text-sm"
  }, [_vm._v("This action can not be undone")]), _c('div', {
    staticClass: "flex justify-center items-center"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Delete",
      "type": "secondary",
      "size": "long",
      "additionalClass": "mr-16"
    },
    on: {
      "action": _vm.deleteMaster
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "primary",
      "size": "long"
    },
    on: {
      "action": _vm.toogleDeleteConfirmModal
    }
  })], 1)])])], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.isAssignmentModalVisible,
      "width": "640px",
      "id": "assingment"
    },
    on: {
      "close": _vm.toogleInstructorAssignmentModal
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "py-12"
  }, [_c('div', {
    staticClass: "font-bold text-2xl"
  }, [_vm._v("Please assign new instructor")]), _c('div', {
    staticClass: "py-5 text-sm"
  }, [_vm._v("The instructor teaches in " + _vm._s(_vm.totalClass) + " classes, you have to find a replacement instructor")]), _c('div', {
    staticClass: "mb-7 flex justify-center"
  }, [_c('div', {
    staticClass: "w-3/5 relative"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("New instructor")]), _c('div', {
    staticClass: "relative"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "enterKeyAction": _vm.onSearchInstructorResult,
      "withIcon": "",
      "borderEnabled": "",
      "placeholder": "Search instructor name"
    },
    model: {
      value: _vm.options2.nama,
      callback: function callback($$v) {
        _vm.$set(_vm.options2, "nama", $$v);
      },
      expression: "options2.nama"
    }
  }), _c('div', {
    staticClass: "absolute left-3 top-1/2 transform -translate-y-1/2"
  }, [_c('Search')], 1)], 1), _c('div', {
    staticClass: "text-xs pt-1 text-yellow text-left"
  }, [_vm._v("click enter to search instructor")]), _vm.isSearching && _vm.options2.nama !== '' && !_vm.isFetching ? _c('div', {
    staticClass: "absolute left-0 py-2 max-h-40 overflow-y-auto rounded-lg w-full bg-neutral-500 bg-opacity-90 z-10"
  }, [_vm.instructorListResult.length > 0 && !_vm.isFetching ? _vm._l(_vm.instructorListResult, function (instructor) {
    return _c('div', {
      key: instructor.id,
      staticClass: "text-left py-1 px-3 text-white cursor-pointer hover:bg-yellow-primary hover:text-white font-normal last:pb-0",
      on: {
        "click": function click($event) {
          return _vm.selectIntructor(instructor);
        }
      }
    }, [_vm._v(" " + _vm._s(instructor.name) + " ")]);
  }) : _vm._e(), _vm.instructorListResult.length === 0 && !_vm.isFetching ? [_c('p', {
    staticClass: "py-1 px-3 text-white font-normal text-center"
  }, [_vm._v("Instructor is not found.")])] : _vm._e()], 2) : _vm._e()])]), _c('div', {
    staticClass: "flex justify-center items-center"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Delete",
      "type": "secondary",
      "disabled": !_vm.instructorSelected.id,
      "size": "long",
      "additionalClass": "mr-16"
    },
    on: {
      "action": _vm.assignIntructor
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "primary",
      "size": "long"
    },
    on: {
      "action": _vm.toogleInstructorAssignmentModal
    }
  })], 1)])])], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }